// exports.onServiceWorkerUpdateReady = () => {
//   var xhttp = new XMLHttpRequest()
//   xhttp.onreadystatechange = function() {
//     if (this.readyState === 4 && this.status === 200) {
//       document.documentElement.innerHTML = this.responseText
//     }
//   }
//   xhttp.open('GET', document.location.href, true)
//   xhttp.send()
// }
exports.onServiceWorkerUpdateFound = () => {
  var xhttp = new XMLHttpRequest()
  xhttp.onreadystatechange = function() {
    if (this.readyState === 4 && this.status === 200) {
      document.documentElement.innerHTML = this.responseText
    }
  }
  xhttp.open('GET', document.location.href, true)
  xhttp.send()
}
